.NavbarItems {
  background: rgb(39, 75, 0);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.logo {
  justify-self: start;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-links {
  color: whitesmoke;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: rgb(26, 25, 25);
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: whitesmoke;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}
