#footer {
  background-color: rgb(26, 25, 25);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem; /* Footer height */
  display: grid;
  grid-template-columns: repeat(3, auto);
  list-style: none;
}

.footer-menu {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  justify-content: left;
  margin-left: 1rem;
  padding-top: 0.6rem;
}

.footer-links {
  color: whitesmoke;
  font-size: small;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.footer-info {
  color: whitesmoke;
  font-size: small;
  padding-top: 0.6rem;
  justify-content: right;
}

.footer-social {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 2px;
  justify-content: right;
  list-style: none;
  padding-top: 0.3rem;
  margin-right: 1rem;
}
