.home-heading {
  padding-top: 50px;
  padding-left: 100px;
  font-size: 40pt;
}

.home {
  grid-template-rows: 2;
  background-color: whitesmoke;
}

.segment {
  background-color: whitesmoke;
  position: relative;
  width: 100%;
  height: 500px;
  display: grid;
  grid-template-columns: repeat(2, auto);
  list-style: none;
}

.segment-1 {
  display: grid;
  background-color: whitesmoke;
  position: absolute;
  bottom: 0;
  padding-right: 0;
  margin-bottom: 40px;
  grid-template-columns: repeat(2, auto);
  list-style: none;
}

.home-body {
  position: relative;
  padding-left: 100px;
  padding-top: 20px;
}

.corner-img {
  width: 100%;
}
